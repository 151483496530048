import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import LicenseManager from "./LicenseManager";
import DiscordCallback from "./DiscordCallback";

function App() {
	return (
		<Router>
			<div className="App">
				<header className="App-header">
					<Routes>
						<Route path="/" element={<LicenseManager />} />
						<Route
							path="/auth/discord/callback"
							element={<DiscordCallback />}
						/>
					</Routes>
				</header>
			</div>
		</Router>
	);
}

export default App;
