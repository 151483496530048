import React, { useState } from "react";

function LicenseManager() {
	const [licenseKey, setLicenseKey] = useState("");
	const [username, setUsername] = useState(""); // Add state for username
	const [error, setError] = useState("");

	const handleGetLicense = async () => {
		try {
			window.location.href =
				"https://license-backend-azure.vercel.app/auth/discord";
		} catch (error) {
			setError("Failed to initiate Discord authentication");
		}
	};

	const handleResetMachineId = async () => {
		try {
			const response = await fetch(
				`https://license-backend-azure.vercel.app/reset-machine-id`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ licenseKey }),
				}
			);
			const data = await response.json();
			if (data.status === "ok") {
				alert("Machine ID reset successfully.");
			} else {
				setError(data.error || "Failed to reset machine ID.");
			}
		} catch (error) {
			setError("Failed to reset machine ID.");
		}
	};

	return (
		<div>
			<h2
				className="dashboard-title"
				style={{ marginBottom: "24px", fontWeight: "bold" }}
			>
				Resoled License Dashboard
			</h2>
			{username && <p>Hello, {username}!</p>} {/* Display username */}
			<button className="button" onClick={handleGetLicense}>
				Get License Key
			</button>
			{licenseKey && (
				<div>
					<p style={{ marginTop: "24px" }}>Your license key: {licenseKey}</p>
					<button className="button" onClick={handleResetMachineId}>
						Reset Machine ID
					</button>
				</div>
			)}
			{error && <p style={{ color: "#ff6b6b", marginTop: "24px" }}>{error}</p>}
		</div>
	);
}

export default LicenseManager;
