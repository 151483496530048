import React, { useEffect, useState } from "react";
import axios from "axios";

function DiscordCallback() {
	const [licenseKey, setLicenseKey] = useState("");
	const [username, setUsername] = useState(""); // Add state for username
	const [error, setError] = useState("");

	useEffect(() => {
		const fetchLicenseKey = async () => {
			const urlParams = new URLSearchParams(window.location.search);
			const code = urlParams.get("code");

			if (code) {
				try {
					const response = await axios.get(
						`https://license-backend-azure.vercel.app/auth/discord/callback?code=${code}`
					);
					if (response.data.licenseKey) {
						setLicenseKey(response.data.licenseKey);
						setUsername(response.data.username); // Set username
					} else if (response.data.error) {
						setError(response.data.error);
					} else {
						setError("No license key received from the server.");
					}
				} catch (error) {
					setError(
						"Failed to get license key: " +
							(error.response?.data?.error || error.message)
					);
				}
			} else {
				setError("No authorization code received from Discord.");
			}
		};

		fetchLicenseKey();
	}, []);

	const handleResetMachineId = async () => {
		try {
			const response = await fetch(
				`https://license-backend-azure.vercel.app/reset-machine-id`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ licenseKey }),
				}
			);
			const data = await response.json();
			if (data.status === "ok") {
				alert("Machine ID reset successfully.");
			} else {
				setError(data.error || "Failed to reset machine ID.");
			}
		} catch (error) {
			setError("Failed to reset machine ID.");
		}
	};

	return (
		<div>
			{licenseKey && (
				<div>
					<p style={{ fontWeight: "bold", fontSize: "1.2em" }}>
						Hello {username}!
					</p>
					<p>Your license key has been generated successfully:</p>
					<p style={{ fontWeight: "bold", fontSize: "1.2em" }}>{licenseKey}</p>
					<button className="button" onClick={handleResetMachineId}>
						Reset Machine ID
					</button>
				</div>
			)}
			{error && <p style={{ color: "red" }}>{error}</p>}
		</div>
	);
}

export default DiscordCallback;
